/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type User = {
  __typename: 'User'
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  dateOfBirth?: string | null
  phoneNumber?: string | null
  nationality?: string | null
  hearAboutUs?: string | null
  referral?: string | null
  taxId?: string | null
  taxIdType?: TaxTypes | null
  country?: string | null
  state?: string | null
  city?: string | null
  street?: string | null
  postalCode?: string | null
  legalAgreements?: boolean | null
  usAccreditedInvestor?: USAccreditedInvestor | null
  bankAccountPayoutUSD?: BankAccountPayoutUSD | null
  bankAccountPayoutNGN?: BankAccountPayoutNGN | null
  identification?: Identification | null
  kycDetails?: KycDetails | null
  Documents?: ModelDocumentsConnection | null
  createdAt: string
  updatedAt: string
  minimumInvestment?: number | null
  minimumDeposit?: number | null
  payments?: ModelPaymentsConnection | null
  monthlyInterestBankPayouts?: ModelMonthlyInterestBankPayoutsConnection | null
  dueInterestBankPayouts?: ModelDueInterestBankPayoutsConnection | null
  withdrawals?: ModelWithdrawalsConnection | null
  loans?: ModelLoanConnection | null
  investments?: ModelInvestmentConnection | null
  references?: ModelReferencesConnection | null
  fxTransactions?: ModelFxTransactionsConnection | null
  clientId?: string | null
  accountId?: string | null
  wallets?: Wallets | null
  otpCode?: OTPCode | null
  onboardingStatus?: OnboardingStatus | null
  kycVerificationStatus?: KycVerificationStatus | null
  monthlyInterestPayoutsOptIn?: boolean | null
  migrationStatus?: MigrationStatus | null
  owner?: string | null
}

export enum TaxTypes {
  SSN = 'SSN',
  TIN = 'TIN',
}

export type USAccreditedInvestor = {
  __typename: 'USAccreditedInvestor'
  annualIncome?: number | null
  netWorth?: string | null
  includesSpouse?: boolean | null
  accreditedInvestorCondition?: string | null
  accreditedInvestorFlag?: boolean | null
  accreditedInvestorExemption?: string | null
}

export type BankAccountPayoutUSD = {
  __typename: 'BankAccountPayoutUSD'
  accountName?: string | null
  accountType?: AccountType | null
  accountNumber?: string | null
  routingType?: RoutingTypes | null
  routingNumber?: string | null
  bankName?: string | null
  iban?: string | null
  swiftCode?: string | null
  bankPostalCode?: string | null
  bankCountry?: string | null
  country?: string | null
  achRoutingNumber?: string | null
  wireRoutingNumber?: string | null
  currency?: string | null
  bankAddress?: string | null
  recipientCountry?: string | null
  recipientAddress?: string | null
  memo?: string | null
}

export enum AccountType {
  PersonalChecking = 'PersonalChecking',
  PersonalSavings = 'PersonalSavings',
  BusinessChecking = 'BusinessChecking',
  BusinessSavings = 'BusinessSavings',
}

export enum RoutingTypes {
  Wire = 'Wire',
  ACH = 'ACH',
}

export type BankAccountPayoutNGN = {
  __typename: 'BankAccountPayoutNGN'
  accountName?: string | null
  accountType?: string | null
  accountNumber?: string | null
  country?: string | null
  achRoutingNumber?: string | null
  bankName?: string | null
  iban?: string | null
  bankPostalCode?: string | null
  recipientCountry?: string | null
  currency?: string | null
  bankAddress?: string | null
  recipientAddress?: string | null
  memo?: string | null
}

export type Identification = {
  __typename: 'Identification'
  idType?: string | null
  idNumber?: string | null
  taxIdType?: TaxTypes | null
  taxId?: string | null
}

export type KycDetails = {
  __typename: 'KycDetails'
  type?: string | null
  identificationNumber?: string | null
  issuingCountry?: string | null
  issueDate?: string | null
  expirationDate?: string | null
  reason?: string | null
  verificationId?: string | null
  documentPhotos?: Array<string | null> | null
  verifiedDelivered?: boolean | null
  rejectedDelivered?: boolean | null
}

export type ModelDocumentsConnection = {
  __typename: 'ModelDocumentsConnection'
  items: Array<Documents | null>
  nextToken?: string | null
}

export type Documents = {
  __typename: 'Documents'
  id: string
  key?: string | null
  bucketName?: string | null
  fileName?: string | null
  name?: string | null
  category?: DocumentCategory | null
  entityType?: EntityTypes | null
  entityId: string
  User?: User | null
  createdAt: string
  updatedAt: string
  owner?: string | null
}

export enum DocumentCategory {
  UserList = 'UserList',
}

export enum EntityTypes {
  User = 'User',
}

export type ModelPaymentsConnection = {
  __typename: 'ModelPaymentsConnection'
  items: Array<Payments | null>
  nextToken?: string | null
}

export type Payments = {
  __typename: 'Payments'
  id: string
  paymentCategory?: PaymentCategory | null
  paymentType?: PaymentType | null
  currency?: string | null
  amount?: number | null
  expectedDate?: string | null
  actualDate?: string | null
  initiatedBy?: string | null
  notes?: string | null
  Status?: PaymentStatus | null
  createdAt: string
  updatedAt: string
  userID: string
  user?: User | null
  investmentID: string
  investment?: Investment | null
  monthlyInterestBankPayouts?: ModelMonthlyInterestBankPayoutsConnection | null
  dueInterestBankPayouts?: ModelDueInterestBankPayoutsConnection | null
  owner?: string | null
}

export enum PaymentCategory {
  Interest = 'Interest',
  Principle = 'Principle',
}

export enum PaymentType {
  Credit = 'Credit',
  Debit = 'Debit',
}

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
}

export type Investment = {
  __typename: 'Investment'
  id: string
  startDate?: string | null
  endDate?: string | null
  actualEndDate?: string | null
  annualInterestRate?: number | null
  renewFlag?: boolean | null
  renewOption?: RenewalOptions | null
  currency?: string | null
  principleAmount?: number | null
  InterestCycleDays?: number | null
  tenorDays?: number | null
  expectedTotalInterest?: number | null
  expectedInterestPerInterestCycle?: number | null
  expectedNumberOfInterestCycles?: number | null
  interestPayoutOption?: InterestPayoutOptions | null
  funded?: boolean | null
  fundedAt?: string | null
  Status?: InvestmentStatus | null
  createdAt: string
  updatedAt: string
  userID: string
  user?: User | null
  payments?: ModelPaymentsConnection | null
  monthlyInterestBankPayouts?: ModelMonthlyInterestBankPayoutsConnection | null
  dueInterestBankPayouts?: ModelDueInterestBankPayoutsConnection | null
  references?: ModelReferencesConnection | null
  fxTransactions?: ModelFxTransactionsConnection | null
  investmentAgreement?: InvestmentAgreement | null
  isBackdated?: boolean | null
  nextInterestPerInterestCycle?: number | null
  isRenewed?: boolean | null
  investmentStep?: InvestmentSteps | null
  owner?: string | null
}

export enum RenewalOptions {
  None = 'None',
  PrincipalOnly = 'PrincipalOnly',
  PrincipalAndInterest = 'PrincipalAndInterest',
}

export enum InterestPayoutOptions {
  Wallet = 'Wallet',
  Bank = 'Bank',
}

export enum InvestmentStatus {
  Active = 'Active',
  Completed = 'Completed',
}

export type ModelMonthlyInterestBankPayoutsConnection = {
  __typename: 'ModelMonthlyInterestBankPayoutsConnection'
  items: Array<MonthlyInterestBankPayouts | null>
  nextToken?: string | null
}

export type MonthlyInterestBankPayouts = {
  __typename: 'MonthlyInterestBankPayouts'
  id: string
  amount?: number | null
  currency?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  country?: string | null
  bankAccountPayoutUSD?: BankAccountPayoutUSD | null
  walletPayinDate: string
  dueBankPayoutDate: string
  payoutStatus?: PaymentStatus | null
  paymentCategory?: PaymentCategory | null
  createdAt: string
  updatedAt: string
  userID: string
  user?: User | null
  investmentID: string
  investment?: Investment | null
  paymentID: string
  payment?: Payments | null
  owner?: string | null
}

export type ModelDueInterestBankPayoutsConnection = {
  __typename: 'ModelDueInterestBankPayoutsConnection'
  items: Array<DueInterestBankPayouts | null>
  nextToken?: string | null
}

export type DueInterestBankPayouts = {
  __typename: 'DueInterestBankPayouts'
  id: string
  amount?: number | null
  currency?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  country?: string | null
  bankAccountPayoutUSD?: BankAccountPayoutUSD | null
  bankAccountPayoutNGN?: BankAccountPayoutNGN | null
  walletPayinDate: string
  dueBankPayoutDate: string
  payoutStatus?: PaymentStatus | null
  paymentCategory?: PaymentCategory | null
  createdAt: string
  updatedAt: string
  userID: string
  user?: User | null
  investmentID: string
  investment?: Investment | null
  paymentID: string
  payment?: Payments | null
  owner?: string | null
}

export type ModelReferencesConnection = {
  __typename: 'ModelReferencesConnection'
  items: Array<References | null>
  nextToken?: string | null
}

export type References = {
  __typename: 'References'
  id: string
  reference: string
  amount: number
  currency?: string | null
  Status?: ReferencesStatus | null
  ProofOfPaymentUrl?: string | null
  notes?: string | null
  investmentID?: string | null
  investment?: Investment | null
  userID: string
  user?: User | null
  createdAt: string
  updatedAt: string
  receivedAt?: string | null
  owner?: string | null
}

export enum ReferencesStatus {
  Pending = 'Pending',
  Sent = 'Sent',
  Completed = 'Completed',
  ProofOfPaymentUploaded = 'ProofOfPaymentUploaded',
  Cancelled = 'Cancelled',
}

export type ModelFxTransactionsConnection = {
  __typename: 'ModelFxTransactionsConnection'
  items: Array<FxTransactions | null>
  nextToken?: string | null
}

export type FxTransactions = {
  __typename: 'FxTransactions'
  id: string
  fxRate?: number | null
  fromCurrency?: string | null
  fromAmount?: number | null
  toCurrency?: string | null
  toAmount?: number | null
  currencyPair?: string | null
  fee?: number | null
  buyPrice?: number | null
  sellPrice?: number | null
  Status?: FxTransactionsStatus | null
  ProofOfPaymentUrl?: string | null
  userReference: string
  convertFromWallet?: boolean | null
  investmentID?: string | null
  investment?: Investment | null
  userID?: string | null
  user?: User | null
  createdAt: string
  updatedAt: string
  owner?: string | null
}

export enum FxTransactionsStatus {
  Pending = 'Pending',
  Sent = 'Sent',
  Converting = 'Converting',
  Completed = 'Completed',
  ProofOfPaymentUploaded = 'ProofOfPaymentUploaded',
}

export type InvestmentAgreement = {
  __typename: 'InvestmentAgreement'
  signed?: boolean | null
  signature?: string | null
  signedAgreementUrl?: string | null
  signedAt: string
}

export enum InvestmentSteps {
  Investment = 'Investment',
  InvestmentRenewalOptions = 'InvestmentRenewalOptions',
  InvestmentPayoutOptions = 'InvestmentPayoutOptions',
  InvestmentAgreement = 'InvestmentAgreement',
  InvestmentConfirmation = 'InvestmentConfirmation',
  InvestmentFundingType = 'InvestmentFundingType',
  InvestmentWalletFund = 'InvestmentWalletFund',
  InvestmentFundTransfer = 'InvestmentFundTransfer',
  InvestmentTransferLater = 'InvestmentTransferLater',
  InvestmentProofOfPayment = 'InvestmentProofOfPayment',
  InvestmentExit = 'InvestmentExit',
  InvestmentSuccess = 'InvestmentSuccess',
}

export type ModelWithdrawalsConnection = {
  __typename: 'ModelWithdrawalsConnection'
  items: Array<Withdrawals | null>
  nextToken?: string | null
}

export type Withdrawals = {
  __typename: 'Withdrawals'
  id: string
  userID: string
  amount?: number | null
  currency?: string | null
  datePaidOut?: string | null
  createdAt: string
  updatedAt: string
  withdrawalStatus?: WithdrawalStatus | null
  user?: User | null
  owner?: string | null
}

export enum WithdrawalStatus {
  completed = 'completed',
  pending = 'pending',
  failed = 'failed',
  cancelled = 'cancelled',
}

export type ModelLoanConnection = {
  __typename: 'ModelLoanConnection'
  items: Array<Loan | null>
  nextToken?: string | null
}

export type Loan = {
  __typename: 'Loan'
  id: string
  startDate?: string | null
  endDate?: string | null
  amount?: number | null
  currency?: string | null
  coverImage?: string | null
  sector?: string | null
  title?: string | null
  country?: string | null
  countryFlag?: string | null
  textOverview?: string | null
  textUseOfFunds?: string | null
  Status?: LoanStatus | null
  createdAt: string
  updatedAt: string
  userID: string
  user?: User | null
  owner?: string | null
}

export enum LoanStatus {
  Active = 'Active',
  Completed = 'Completed',
  Default = 'Default',
}

export type ModelInvestmentConnection = {
  __typename: 'ModelInvestmentConnection'
  items: Array<Investment | null>
  nextToken?: string | null
}

export type Wallets = {
  __typename: 'Wallets'
  USD?: WalletDetails | null
  NGN?: WalletDetails | null
}

export type WalletDetails = {
  __typename: 'WalletDetails'
  id?: string | null
  accountNumber?: string | null
  currency?: string | null
}

export type OTPCode = {
  __typename: 'OTPCode'
  code?: string | null
  timestamp?: number | null
}

export enum OnboardingStatus {
  completed = 'completed',
  pending = 'pending',
}

export enum KycVerificationStatus {
  notStarted = 'notStarted',
  started = 'started',
  pending = 'pending',
  completed = 'completed',
  verified = 'verified',
  finished = 'finished',
  rejected = 'rejected',
}

export enum MigrationStatus {
  offline = 'offline',
  pending = 'pending',
  online = 'online',
}

export type ModelIDKeyConditionInput = {
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
}

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type ModelInvestmentFilterInput = {
  id?: ModelIDInput | null
  startDate?: ModelStringInput | null
  endDate?: ModelStringInput | null
  actualEndDate?: ModelStringInput | null
  annualInterestRate?: ModelFloatInput | null
  renewFlag?: ModelBooleanInput | null
  renewOption?: ModelRenewalOptionsInput | null
  currency?: ModelStringInput | null
  principleAmount?: ModelFloatInput | null
  InterestCycleDays?: ModelIntInput | null
  tenorDays?: ModelIntInput | null
  expectedTotalInterest?: ModelFloatInput | null
  expectedInterestPerInterestCycle?: ModelFloatInput | null
  expectedNumberOfInterestCycles?: ModelFloatInput | null
  interestPayoutOption?: ModelInterestPayoutOptionsInput | null
  funded?: ModelBooleanInput | null
  fundedAt?: ModelStringInput | null
  Status?: ModelInvestmentStatusInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  isBackdated?: ModelBooleanInput | null
  nextInterestPerInterestCycle?: ModelFloatInput | null
  isRenewed?: ModelBooleanInput | null
  investmentStep?: ModelInvestmentStepsInput | null
  and?: Array<ModelInvestmentFilterInput | null> | null
  or?: Array<ModelInvestmentFilterInput | null> | null
  not?: ModelInvestmentFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelIDInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
  size?: ModelSizeInput | null
}

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
}

export type ModelStringInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
  size?: ModelSizeInput | null
}

export type ModelFloatInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
}

export type ModelBooleanInput = {
  ne?: boolean | null
  eq?: boolean | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
}

export type ModelRenewalOptionsInput = {
  eq?: RenewalOptions | null
  ne?: RenewalOptions | null
}

export type ModelIntInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
}

export type ModelInterestPayoutOptionsInput = {
  eq?: InterestPayoutOptions | null
  ne?: InterestPayoutOptions | null
}

export type ModelInvestmentStatusInput = {
  eq?: InvestmentStatus | null
  ne?: InvestmentStatus | null
}

export type ModelInvestmentStepsInput = {
  eq?: InvestmentSteps | null
  ne?: InvestmentSteps | null
}

export type UpdateReferencesInput = {
  id: string
  reference?: string | null
  amount?: number | null
  currency?: string | null
  Status?: ReferencesStatus | null
  ProofOfPaymentUrl?: string | null
  notes?: string | null
  investmentID?: string | null
  userID?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  receivedAt?: string | null
}

export type ModelReferencesConditionInput = {
  reference?: ModelStringInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  Status?: ModelReferencesStatusInput | null
  ProofOfPaymentUrl?: ModelStringInput | null
  notes?: ModelStringInput | null
  investmentID?: ModelIDInput | null
  userID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  receivedAt?: ModelStringInput | null
  and?: Array<ModelReferencesConditionInput | null> | null
  or?: Array<ModelReferencesConditionInput | null> | null
  not?: ModelReferencesConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelReferencesStatusInput = {
  eq?: ReferencesStatus | null
  ne?: ReferencesStatus | null
}

export type CreateUserInput = {
  id?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  dateOfBirth?: string | null
  phoneNumber?: string | null
  nationality?: string | null
  hearAboutUs?: string | null
  referral?: string | null
  taxId?: string | null
  taxIdType?: TaxTypes | null
  country?: string | null
  state?: string | null
  city?: string | null
  street?: string | null
  postalCode?: string | null
  legalAgreements?: boolean | null
  usAccreditedInvestor?: USAccreditedInvestorInput | null
  bankAccountPayoutUSD?: BankAccountPayoutUSDInput | null
  bankAccountPayoutNGN?: BankAccountPayoutNGNInput | null
  identification?: IdentificationInput | null
  kycDetails?: KycDetailsInput | null
  createdAt?: string | null
  updatedAt?: string | null
  minimumInvestment?: number | null
  minimumDeposit?: number | null
  clientId?: string | null
  accountId?: string | null
  wallets?: WalletsInput | null
  otpCode?: OTPCodeInput | null
  onboardingStatus?: OnboardingStatus | null
  kycVerificationStatus?: KycVerificationStatus | null
  monthlyInterestPayoutsOptIn?: boolean | null
  migrationStatus?: MigrationStatus | null
}

export type USAccreditedInvestorInput = {
  annualIncome?: number | null
  netWorth?: string | null
  includesSpouse?: boolean | null
  accreditedInvestorCondition?: string | null
  accreditedInvestorFlag?: boolean | null
  accreditedInvestorExemption?: string | null
}

export type BankAccountPayoutUSDInput = {
  accountName?: string | null
  accountType?: AccountType | null
  accountNumber?: string | null
  routingType?: RoutingTypes | null
  routingNumber?: string | null
  bankName?: string | null
  iban?: string | null
  swiftCode?: string | null
  bankPostalCode?: string | null
  bankCountry?: string | null
  country?: string | null
  achRoutingNumber?: string | null
  wireRoutingNumber?: string | null
  currency?: string | null
  bankAddress?: string | null
  recipientCountry?: string | null
  recipientAddress?: string | null
  memo?: string | null
}

export type BankAccountPayoutNGNInput = {
  accountName?: string | null
  accountType?: string | null
  accountNumber?: string | null
  country?: string | null
  achRoutingNumber?: string | null
  bankName?: string | null
  iban?: string | null
  bankPostalCode?: string | null
  recipientCountry?: string | null
  currency?: string | null
  bankAddress?: string | null
  recipientAddress?: string | null
  memo?: string | null
}

export type IdentificationInput = {
  idType?: string | null
  idNumber?: string | null
  taxIdType?: TaxTypes | null
  taxId?: string | null
}

export type KycDetailsInput = {
  type?: string | null
  identificationNumber?: string | null
  issuingCountry?: string | null
  issueDate?: string | null
  expirationDate?: string | null
  reason?: string | null
  verificationId?: string | null
  documentPhotos?: Array<string | null> | null
  verifiedDelivered?: boolean | null
  rejectedDelivered?: boolean | null
}

export type WalletsInput = {
  USD?: WalletDetailsInput | null
  NGN?: WalletDetailsInput | null
}

export type WalletDetailsInput = {
  id?: string | null
  accountNumber?: string | null
  currency?: string | null
}

export type OTPCodeInput = {
  code?: string | null
  timestamp?: number | null
}

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  dateOfBirth?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  nationality?: ModelStringInput | null
  hearAboutUs?: ModelStringInput | null
  referral?: ModelStringInput | null
  taxId?: ModelStringInput | null
  taxIdType?: ModelTaxTypesInput | null
  country?: ModelStringInput | null
  state?: ModelStringInput | null
  city?: ModelStringInput | null
  street?: ModelStringInput | null
  postalCode?: ModelStringInput | null
  legalAgreements?: ModelBooleanInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  minimumInvestment?: ModelFloatInput | null
  minimumDeposit?: ModelFloatInput | null
  clientId?: ModelStringInput | null
  accountId?: ModelStringInput | null
  onboardingStatus?: ModelOnboardingStatusInput | null
  kycVerificationStatus?: ModelKycVerificationStatusInput | null
  monthlyInterestPayoutsOptIn?: ModelBooleanInput | null
  migrationStatus?: ModelMigrationStatusInput | null
  and?: Array<ModelUserConditionInput | null> | null
  or?: Array<ModelUserConditionInput | null> | null
  not?: ModelUserConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelTaxTypesInput = {
  eq?: TaxTypes | null
  ne?: TaxTypes | null
}

export type ModelOnboardingStatusInput = {
  eq?: OnboardingStatus | null
  ne?: OnboardingStatus | null
}

export type ModelKycVerificationStatusInput = {
  eq?: KycVerificationStatus | null
  ne?: KycVerificationStatus | null
}

export type ModelMigrationStatusInput = {
  eq?: MigrationStatus | null
  ne?: MigrationStatus | null
}

export type UpdateUserInput = {
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  dateOfBirth?: string | null
  phoneNumber?: string | null
  nationality?: string | null
  hearAboutUs?: string | null
  referral?: string | null
  taxId?: string | null
  taxIdType?: TaxTypes | null
  country?: string | null
  state?: string | null
  city?: string | null
  street?: string | null
  postalCode?: string | null
  legalAgreements?: boolean | null
  usAccreditedInvestor?: USAccreditedInvestorInput | null
  bankAccountPayoutUSD?: BankAccountPayoutUSDInput | null
  bankAccountPayoutNGN?: BankAccountPayoutNGNInput | null
  identification?: IdentificationInput | null
  kycDetails?: KycDetailsInput | null
  createdAt?: string | null
  updatedAt?: string | null
  minimumInvestment?: number | null
  minimumDeposit?: number | null
  clientId?: string | null
  accountId?: string | null
  wallets?: WalletsInput | null
  otpCode?: OTPCodeInput | null
  onboardingStatus?: OnboardingStatus | null
  kycVerificationStatus?: KycVerificationStatus | null
  monthlyInterestPayoutsOptIn?: boolean | null
  migrationStatus?: MigrationStatus | null
}

export type DeleteUserInput = {
  id: string
}

export type CreateDocumentsInput = {
  id?: string | null
  key?: string | null
  bucketName?: string | null
  fileName?: string | null
  name?: string | null
  category?: DocumentCategory | null
  entityType?: EntityTypes | null
  entityId: string
  createdAt?: string | null
  updatedAt?: string | null
}

export type ModelDocumentsConditionInput = {
  key?: ModelStringInput | null
  bucketName?: ModelStringInput | null
  fileName?: ModelStringInput | null
  name?: ModelStringInput | null
  category?: ModelDocumentCategoryInput | null
  entityType?: ModelEntityTypesInput | null
  entityId?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  and?: Array<ModelDocumentsConditionInput | null> | null
  or?: Array<ModelDocumentsConditionInput | null> | null
  not?: ModelDocumentsConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelDocumentCategoryInput = {
  eq?: DocumentCategory | null
  ne?: DocumentCategory | null
}

export type ModelEntityTypesInput = {
  eq?: EntityTypes | null
  ne?: EntityTypes | null
}

export type UpdateDocumentsInput = {
  id: string
  key?: string | null
  bucketName?: string | null
  fileName?: string | null
  name?: string | null
  category?: DocumentCategory | null
  entityType?: EntityTypes | null
  entityId?: string | null
  createdAt?: string | null
  updatedAt?: string | null
}

export type DeleteDocumentsInput = {
  id: string
}

export type CreateLoanInput = {
  id?: string | null
  startDate?: string | null
  endDate?: string | null
  amount?: number | null
  currency?: string | null
  coverImage?: string | null
  sector?: string | null
  title?: string | null
  country?: string | null
  countryFlag?: string | null
  textOverview?: string | null
  textUseOfFunds?: string | null
  Status?: LoanStatus | null
  createdAt?: string | null
  updatedAt?: string | null
  userID: string
}

export type ModelLoanConditionInput = {
  startDate?: ModelStringInput | null
  endDate?: ModelStringInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  coverImage?: ModelStringInput | null
  sector?: ModelStringInput | null
  title?: ModelStringInput | null
  country?: ModelStringInput | null
  countryFlag?: ModelStringInput | null
  textOverview?: ModelStringInput | null
  textUseOfFunds?: ModelStringInput | null
  Status?: ModelLoanStatusInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  and?: Array<ModelLoanConditionInput | null> | null
  or?: Array<ModelLoanConditionInput | null> | null
  not?: ModelLoanConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelLoanStatusInput = {
  eq?: LoanStatus | null
  ne?: LoanStatus | null
}

export type UpdateLoanInput = {
  id: string
  startDate?: string | null
  endDate?: string | null
  amount?: number | null
  currency?: string | null
  coverImage?: string | null
  sector?: string | null
  title?: string | null
  country?: string | null
  countryFlag?: string | null
  textOverview?: string | null
  textUseOfFunds?: string | null
  Status?: LoanStatus | null
  createdAt?: string | null
  updatedAt?: string | null
  userID?: string | null
}

export type DeleteLoanInput = {
  id: string
}

export type CreateWithdrawalsInput = {
  id?: string | null
  userID: string
  amount?: number | null
  currency?: string | null
  datePaidOut?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  withdrawalStatus?: WithdrawalStatus | null
}

export type ModelWithdrawalsConditionInput = {
  userID?: ModelIDInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  datePaidOut?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  withdrawalStatus?: ModelWithdrawalStatusInput | null
  and?: Array<ModelWithdrawalsConditionInput | null> | null
  or?: Array<ModelWithdrawalsConditionInput | null> | null
  not?: ModelWithdrawalsConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelWithdrawalStatusInput = {
  eq?: WithdrawalStatus | null
  ne?: WithdrawalStatus | null
}

export type UpdateWithdrawalsInput = {
  id: string
  userID?: string | null
  amount?: number | null
  currency?: string | null
  datePaidOut?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  withdrawalStatus?: WithdrawalStatus | null
}

export type DeleteWithdrawalsInput = {
  id: string
}

export type CreateInvestmentInput = {
  id?: string | null
  startDate?: string | null
  endDate?: string | null
  actualEndDate?: string | null
  annualInterestRate?: number | null
  renewFlag?: boolean | null
  renewOption?: RenewalOptions | null
  currency?: string | null
  principleAmount?: number | null
  InterestCycleDays?: number | null
  tenorDays?: number | null
  expectedTotalInterest?: number | null
  expectedInterestPerInterestCycle?: number | null
  expectedNumberOfInterestCycles?: number | null
  interestPayoutOption?: InterestPayoutOptions | null
  funded?: boolean | null
  fundedAt?: string | null
  Status?: InvestmentStatus | null
  createdAt?: string | null
  updatedAt?: string | null
  userID: string
  investmentAgreement?: InvestmentAgreementInput | null
  isBackdated?: boolean | null
  nextInterestPerInterestCycle?: number | null
  isRenewed?: boolean | null
  investmentStep?: InvestmentSteps | null
}

export type InvestmentAgreementInput = {
  signed?: boolean | null
  signature?: string | null
  signedAgreementUrl?: string | null
  signedAt: string
}

export type ModelInvestmentConditionInput = {
  startDate?: ModelStringInput | null
  endDate?: ModelStringInput | null
  actualEndDate?: ModelStringInput | null
  annualInterestRate?: ModelFloatInput | null
  renewFlag?: ModelBooleanInput | null
  renewOption?: ModelRenewalOptionsInput | null
  currency?: ModelStringInput | null
  principleAmount?: ModelFloatInput | null
  InterestCycleDays?: ModelIntInput | null
  tenorDays?: ModelIntInput | null
  expectedTotalInterest?: ModelFloatInput | null
  expectedInterestPerInterestCycle?: ModelFloatInput | null
  expectedNumberOfInterestCycles?: ModelFloatInput | null
  interestPayoutOption?: ModelInterestPayoutOptionsInput | null
  funded?: ModelBooleanInput | null
  fundedAt?: ModelStringInput | null
  Status?: ModelInvestmentStatusInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  isBackdated?: ModelBooleanInput | null
  nextInterestPerInterestCycle?: ModelFloatInput | null
  isRenewed?: ModelBooleanInput | null
  investmentStep?: ModelInvestmentStepsInput | null
  and?: Array<ModelInvestmentConditionInput | null> | null
  or?: Array<ModelInvestmentConditionInput | null> | null
  not?: ModelInvestmentConditionInput | null
  owner?: ModelStringInput | null
}

export type UpdateInvestmentInput = {
  id: string
  startDate?: string | null
  endDate?: string | null
  actualEndDate?: string | null
  annualInterestRate?: number | null
  renewFlag?: boolean | null
  renewOption?: RenewalOptions | null
  currency?: string | null
  principleAmount?: number | null
  InterestCycleDays?: number | null
  tenorDays?: number | null
  expectedTotalInterest?: number | null
  expectedInterestPerInterestCycle?: number | null
  expectedNumberOfInterestCycles?: number | null
  interestPayoutOption?: InterestPayoutOptions | null
  funded?: boolean | null
  fundedAt?: string | null
  Status?: InvestmentStatus | null
  createdAt?: string | null
  updatedAt?: string | null
  userID?: string | null
  investmentAgreement?: InvestmentAgreementInput | null
  isBackdated?: boolean | null
  nextInterestPerInterestCycle?: number | null
  isRenewed?: boolean | null
  investmentStep?: InvestmentSteps | null
}

export type DeleteInvestmentInput = {
  id: string
}

export type CreatePaymentsInput = {
  id?: string | null
  paymentCategory?: PaymentCategory | null
  paymentType?: PaymentType | null
  currency?: string | null
  amount?: number | null
  expectedDate?: string | null
  actualDate?: string | null
  initiatedBy?: string | null
  notes?: string | null
  Status?: PaymentStatus | null
  createdAt?: string | null
  updatedAt?: string | null
  userID: string
  investmentID: string
}

export type ModelPaymentsConditionInput = {
  paymentCategory?: ModelPaymentCategoryInput | null
  paymentType?: ModelPaymentTypeInput | null
  currency?: ModelStringInput | null
  amount?: ModelFloatInput | null
  expectedDate?: ModelStringInput | null
  actualDate?: ModelStringInput | null
  initiatedBy?: ModelStringInput | null
  notes?: ModelStringInput | null
  Status?: ModelPaymentStatusInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  investmentID?: ModelIDInput | null
  and?: Array<ModelPaymentsConditionInput | null> | null
  or?: Array<ModelPaymentsConditionInput | null> | null
  not?: ModelPaymentsConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelPaymentCategoryInput = {
  eq?: PaymentCategory | null
  ne?: PaymentCategory | null
}

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null
  ne?: PaymentType | null
}

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null
  ne?: PaymentStatus | null
}

export type UpdatePaymentsInput = {
  id: string
  paymentCategory?: PaymentCategory | null
  paymentType?: PaymentType | null
  currency?: string | null
  amount?: number | null
  expectedDate?: string | null
  actualDate?: string | null
  initiatedBy?: string | null
  notes?: string | null
  Status?: PaymentStatus | null
  createdAt?: string | null
  updatedAt?: string | null
  userID?: string | null
  investmentID?: string | null
}

export type DeletePaymentsInput = {
  id: string
}

export type CreateMonthlyInterestBankPayoutsInput = {
  id?: string | null
  amount?: number | null
  currency?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  country?: string | null
  bankAccountPayoutUSD?: BankAccountPayoutUSDInput | null
  walletPayinDate: string
  dueBankPayoutDate: string
  payoutStatus?: PaymentStatus | null
  paymentCategory?: PaymentCategory | null
  createdAt?: string | null
  updatedAt?: string | null
  userID: string
  investmentID: string
  paymentID: string
}

export type ModelMonthlyInterestBankPayoutsConditionInput = {
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  country?: ModelStringInput | null
  walletPayinDate?: ModelStringInput | null
  dueBankPayoutDate?: ModelStringInput | null
  payoutStatus?: ModelPaymentStatusInput | null
  paymentCategory?: ModelPaymentCategoryInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  investmentID?: ModelIDInput | null
  paymentID?: ModelIDInput | null
  and?: Array<ModelMonthlyInterestBankPayoutsConditionInput | null> | null
  or?: Array<ModelMonthlyInterestBankPayoutsConditionInput | null> | null
  not?: ModelMonthlyInterestBankPayoutsConditionInput | null
  owner?: ModelStringInput | null
}

export type UpdateMonthlyInterestBankPayoutsInput = {
  id: string
  amount?: number | null
  currency?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  country?: string | null
  bankAccountPayoutUSD?: BankAccountPayoutUSDInput | null
  walletPayinDate?: string | null
  dueBankPayoutDate?: string | null
  payoutStatus?: PaymentStatus | null
  paymentCategory?: PaymentCategory | null
  createdAt?: string | null
  updatedAt?: string | null
  userID?: string | null
  investmentID?: string | null
  paymentID?: string | null
}

export type DeleteMonthlyInterestBankPayoutsInput = {
  id: string
}

export type CreateDueInterestBankPayoutsInput = {
  id?: string | null
  amount?: number | null
  currency?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  country?: string | null
  bankAccountPayoutUSD?: BankAccountPayoutUSDInput | null
  bankAccountPayoutNGN?: BankAccountPayoutNGNInput | null
  walletPayinDate: string
  dueBankPayoutDate: string
  payoutStatus?: PaymentStatus | null
  paymentCategory?: PaymentCategory | null
  createdAt?: string | null
  updatedAt?: string | null
  userID: string
  investmentID: string
  paymentID: string
}

export type ModelDueInterestBankPayoutsConditionInput = {
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  country?: ModelStringInput | null
  walletPayinDate?: ModelStringInput | null
  dueBankPayoutDate?: ModelStringInput | null
  payoutStatus?: ModelPaymentStatusInput | null
  paymentCategory?: ModelPaymentCategoryInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  investmentID?: ModelIDInput | null
  paymentID?: ModelIDInput | null
  and?: Array<ModelDueInterestBankPayoutsConditionInput | null> | null
  or?: Array<ModelDueInterestBankPayoutsConditionInput | null> | null
  not?: ModelDueInterestBankPayoutsConditionInput | null
  owner?: ModelStringInput | null
}

export type UpdateDueInterestBankPayoutsInput = {
  id: string
  amount?: number | null
  currency?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phoneNumber?: string | null
  country?: string | null
  bankAccountPayoutUSD?: BankAccountPayoutUSDInput | null
  bankAccountPayoutNGN?: BankAccountPayoutNGNInput | null
  walletPayinDate?: string | null
  dueBankPayoutDate?: string | null
  payoutStatus?: PaymentStatus | null
  paymentCategory?: PaymentCategory | null
  createdAt?: string | null
  updatedAt?: string | null
  userID?: string | null
  investmentID?: string | null
  paymentID?: string | null
}

export type DeleteDueInterestBankPayoutsInput = {
  id: string
}

export type CreateReferencesInput = {
  id?: string | null
  reference: string
  amount: number
  currency?: string | null
  Status?: ReferencesStatus | null
  ProofOfPaymentUrl?: string | null
  notes?: string | null
  investmentID?: string | null
  userID: string
  createdAt?: string | null
  updatedAt?: string | null
  receivedAt?: string | null
}

export type DeleteReferencesInput = {
  id: string
}

export type CreateFxTransactionsInput = {
  id?: string | null
  fxRate?: number | null
  fromCurrency?: string | null
  fromAmount?: number | null
  toCurrency?: string | null
  toAmount?: number | null
  currencyPair?: string | null
  fee?: number | null
  buyPrice?: number | null
  sellPrice?: number | null
  Status?: FxTransactionsStatus | null
  ProofOfPaymentUrl?: string | null
  userReference: string
  convertFromWallet?: boolean | null
  investmentID?: string | null
  userID?: string | null
  createdAt?: string | null
  updatedAt?: string | null
}

export type ModelFxTransactionsConditionInput = {
  fxRate?: ModelFloatInput | null
  fromCurrency?: ModelStringInput | null
  fromAmount?: ModelFloatInput | null
  toCurrency?: ModelStringInput | null
  toAmount?: ModelFloatInput | null
  currencyPair?: ModelStringInput | null
  fee?: ModelFloatInput | null
  buyPrice?: ModelFloatInput | null
  sellPrice?: ModelFloatInput | null
  Status?: ModelFxTransactionsStatusInput | null
  ProofOfPaymentUrl?: ModelStringInput | null
  userReference?: ModelStringInput | null
  convertFromWallet?: ModelBooleanInput | null
  investmentID?: ModelIDInput | null
  userID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  and?: Array<ModelFxTransactionsConditionInput | null> | null
  or?: Array<ModelFxTransactionsConditionInput | null> | null
  not?: ModelFxTransactionsConditionInput | null
  owner?: ModelStringInput | null
}

export type ModelFxTransactionsStatusInput = {
  eq?: FxTransactionsStatus | null
  ne?: FxTransactionsStatus | null
}

export type UpdateFxTransactionsInput = {
  id: string
  fxRate?: number | null
  fromCurrency?: string | null
  fromAmount?: number | null
  toCurrency?: string | null
  toAmount?: number | null
  currencyPair?: string | null
  fee?: number | null
  buyPrice?: number | null
  sellPrice?: number | null
  Status?: FxTransactionsStatus | null
  ProofOfPaymentUrl?: string | null
  userReference?: string | null
  convertFromWallet?: boolean | null
  investmentID?: string | null
  userID?: string | null
  createdAt?: string | null
  updatedAt?: string | null
}

export type DeleteFxTransactionsInput = {
  id: string
}

export type CreatePlatformMetricsInput = {
  id?: string | null
  data: PlatformMetricsDataInput
}

export type PlatformMetricsDataInput = {
  platform: PlatformDataInput
  createdAt: string
}

export type PlatformDataInput = {
  totalUsers: number
  activeUsers: number
  averageInterestRate: number
  hniCapital: number
}

export type ModelPlatformMetricsConditionInput = {
  and?: Array<ModelPlatformMetricsConditionInput | null> | null
  or?: Array<ModelPlatformMetricsConditionInput | null> | null
  not?: ModelPlatformMetricsConditionInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  owner?: ModelStringInput | null
}

export type PlatformMetrics = {
  __typename: 'PlatformMetrics'
  id: string
  data: PlatformMetricsData
  createdAt: string
  updatedAt: string
  owner?: string | null
}

export type PlatformMetricsData = {
  __typename: 'PlatformMetricsData'
  platform: PlatformData
  createdAt: string
}

export type PlatformData = {
  __typename: 'PlatformData'
  totalUsers: number
  activeUsers: number
  averageInterestRate: number
  hniCapital: number
}

export type UpdatePlatformMetricsInput = {
  id: string
  data?: PlatformMetricsDataInput | null
}

export type DeletePlatformMetricsInput = {
  id: string
}

export type UserBalances = {
  __typename: 'UserBalances'
  walletBalance?: WalletBalances | null
  activeInvestment?: WalletBalances | null
  totalBalance?: WalletBalances | null
  lifetimeInvestments?: WalletBalances | null
  lifetimeInterestEarned?: WalletBalances | null
  weightedAnnualInterestRate?: WalletBalances | null
  nextInterestPayout?: InterestPayout | null
  lastWithdrawalAmount?: number | null
}

export type WalletBalances = {
  __typename: 'WalletBalances'
  USD?: number | null
  NGN?: number | null
}

export type InterestPayout = {
  __typename: 'InterestPayout'
  amount?: number | null
  currency?: string | null
  expectedDate?: string | null
}

export type UserInfo = {
  id?: string | null
  name?: string | null
  email?: string | null
}

export type OTPTokenResponse = {
  __typename: 'OTPTokenResponse'
  status?: number | null
  message?: string | null
  tokenValid?: boolean | null
}

export type WithdrawalResponse = {
  __typename: 'WithdrawalResponse'
  message?: string | null
  error?: string | null
  status?: number | null
}

export type GetExchangeRatesResponse = {
  __typename: 'GetExchangeRatesResponse'
  exchangeRate?: number | null
}

export enum AgreementTypes {
  Investment = 'Investment',
}

export type GetSigningUrlResponse = {
  __typename: 'GetSigningUrlResponse'
  signatureUrl?: string | null
}

export type ModelUserFilterInput = {
  id?: ModelIDInput | null
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  dateOfBirth?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  nationality?: ModelStringInput | null
  hearAboutUs?: ModelStringInput | null
  referral?: ModelStringInput | null
  taxId?: ModelStringInput | null
  taxIdType?: ModelTaxTypesInput | null
  country?: ModelStringInput | null
  state?: ModelStringInput | null
  city?: ModelStringInput | null
  street?: ModelStringInput | null
  postalCode?: ModelStringInput | null
  legalAgreements?: ModelBooleanInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  minimumInvestment?: ModelFloatInput | null
  minimumDeposit?: ModelFloatInput | null
  clientId?: ModelStringInput | null
  accountId?: ModelStringInput | null
  onboardingStatus?: ModelOnboardingStatusInput | null
  kycVerificationStatus?: ModelKycVerificationStatusInput | null
  monthlyInterestPayoutsOptIn?: ModelBooleanInput | null
  migrationStatus?: ModelMigrationStatusInput | null
  and?: Array<ModelUserFilterInput | null> | null
  or?: Array<ModelUserFilterInput | null> | null
  not?: ModelUserFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelUserConnection = {
  __typename: 'ModelUserConnection'
  items: Array<User | null>
  nextToken?: string | null
}

export type ModelDocumentsFilterInput = {
  id?: ModelIDInput | null
  key?: ModelStringInput | null
  bucketName?: ModelStringInput | null
  fileName?: ModelStringInput | null
  name?: ModelStringInput | null
  category?: ModelDocumentCategoryInput | null
  entityType?: ModelEntityTypesInput | null
  entityId?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  and?: Array<ModelDocumentsFilterInput | null> | null
  or?: Array<ModelDocumentsFilterInput | null> | null
  not?: ModelDocumentsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelLoanFilterInput = {
  id?: ModelIDInput | null
  startDate?: ModelStringInput | null
  endDate?: ModelStringInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  coverImage?: ModelStringInput | null
  sector?: ModelStringInput | null
  title?: ModelStringInput | null
  country?: ModelStringInput | null
  countryFlag?: ModelStringInput | null
  textOverview?: ModelStringInput | null
  textUseOfFunds?: ModelStringInput | null
  Status?: ModelLoanStatusInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  and?: Array<ModelLoanFilterInput | null> | null
  or?: Array<ModelLoanFilterInput | null> | null
  not?: ModelLoanFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelWithdrawalsFilterInput = {
  id?: ModelIDInput | null
  userID?: ModelIDInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  datePaidOut?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  withdrawalStatus?: ModelWithdrawalStatusInput | null
  and?: Array<ModelWithdrawalsFilterInput | null> | null
  or?: Array<ModelWithdrawalsFilterInput | null> | null
  not?: ModelWithdrawalsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelPaymentsFilterInput = {
  id?: ModelIDInput | null
  paymentCategory?: ModelPaymentCategoryInput | null
  paymentType?: ModelPaymentTypeInput | null
  currency?: ModelStringInput | null
  amount?: ModelFloatInput | null
  expectedDate?: ModelStringInput | null
  actualDate?: ModelStringInput | null
  initiatedBy?: ModelStringInput | null
  notes?: ModelStringInput | null
  Status?: ModelPaymentStatusInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  investmentID?: ModelIDInput | null
  and?: Array<ModelPaymentsFilterInput | null> | null
  or?: Array<ModelPaymentsFilterInput | null> | null
  not?: ModelPaymentsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelMonthlyInterestBankPayoutsFilterInput = {
  id?: ModelIDInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  country?: ModelStringInput | null
  walletPayinDate?: ModelStringInput | null
  dueBankPayoutDate?: ModelStringInput | null
  payoutStatus?: ModelPaymentStatusInput | null
  paymentCategory?: ModelPaymentCategoryInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  investmentID?: ModelIDInput | null
  paymentID?: ModelIDInput | null
  and?: Array<ModelMonthlyInterestBankPayoutsFilterInput | null> | null
  or?: Array<ModelMonthlyInterestBankPayoutsFilterInput | null> | null
  not?: ModelMonthlyInterestBankPayoutsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelDueInterestBankPayoutsFilterInput = {
  id?: ModelIDInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  firstName?: ModelStringInput | null
  lastName?: ModelStringInput | null
  email?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  country?: ModelStringInput | null
  walletPayinDate?: ModelStringInput | null
  dueBankPayoutDate?: ModelStringInput | null
  payoutStatus?: ModelPaymentStatusInput | null
  paymentCategory?: ModelPaymentCategoryInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  userID?: ModelIDInput | null
  investmentID?: ModelIDInput | null
  paymentID?: ModelIDInput | null
  and?: Array<ModelDueInterestBankPayoutsFilterInput | null> | null
  or?: Array<ModelDueInterestBankPayoutsFilterInput | null> | null
  not?: ModelDueInterestBankPayoutsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelReferencesFilterInput = {
  id?: ModelIDInput | null
  reference?: ModelStringInput | null
  amount?: ModelFloatInput | null
  currency?: ModelStringInput | null
  Status?: ModelReferencesStatusInput | null
  ProofOfPaymentUrl?: ModelStringInput | null
  notes?: ModelStringInput | null
  investmentID?: ModelIDInput | null
  userID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  receivedAt?: ModelStringInput | null
  and?: Array<ModelReferencesFilterInput | null> | null
  or?: Array<ModelReferencesFilterInput | null> | null
  not?: ModelReferencesFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelFxTransactionsFilterInput = {
  id?: ModelIDInput | null
  fxRate?: ModelFloatInput | null
  fromCurrency?: ModelStringInput | null
  fromAmount?: ModelFloatInput | null
  toCurrency?: ModelStringInput | null
  toAmount?: ModelFloatInput | null
  currencyPair?: ModelStringInput | null
  fee?: ModelFloatInput | null
  buyPrice?: ModelFloatInput | null
  sellPrice?: ModelFloatInput | null
  Status?: ModelFxTransactionsStatusInput | null
  ProofOfPaymentUrl?: ModelStringInput | null
  userReference?: ModelStringInput | null
  convertFromWallet?: ModelBooleanInput | null
  investmentID?: ModelIDInput | null
  userID?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  and?: Array<ModelFxTransactionsFilterInput | null> | null
  or?: Array<ModelFxTransactionsFilterInput | null> | null
  not?: ModelFxTransactionsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelPlatformMetricsFilterInput = {
  id?: ModelIDInput | null
  createdAt?: ModelStringInput | null
  updatedAt?: ModelStringInput | null
  and?: Array<ModelPlatformMetricsFilterInput | null> | null
  or?: Array<ModelPlatformMetricsFilterInput | null> | null
  not?: ModelPlatformMetricsFilterInput | null
  owner?: ModelStringInput | null
}

export type ModelPlatformMetricsConnection = {
  __typename: 'ModelPlatformMetricsConnection'
  items: Array<PlatformMetrics | null>
  nextToken?: string | null
}

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null
  firstName?: ModelSubscriptionStringInput | null
  lastName?: ModelSubscriptionStringInput | null
  email?: ModelSubscriptionStringInput | null
  dateOfBirth?: ModelSubscriptionStringInput | null
  phoneNumber?: ModelSubscriptionStringInput | null
  nationality?: ModelSubscriptionStringInput | null
  hearAboutUs?: ModelSubscriptionStringInput | null
  referral?: ModelSubscriptionStringInput | null
  taxId?: ModelSubscriptionStringInput | null
  taxIdType?: ModelSubscriptionStringInput | null
  country?: ModelSubscriptionStringInput | null
  state?: ModelSubscriptionStringInput | null
  city?: ModelSubscriptionStringInput | null
  street?: ModelSubscriptionStringInput | null
  postalCode?: ModelSubscriptionStringInput | null
  legalAgreements?: ModelSubscriptionBooleanInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  minimumInvestment?: ModelSubscriptionFloatInput | null
  minimumDeposit?: ModelSubscriptionFloatInput | null
  clientId?: ModelSubscriptionStringInput | null
  accountId?: ModelSubscriptionStringInput | null
  onboardingStatus?: ModelSubscriptionStringInput | null
  kycVerificationStatus?: ModelSubscriptionStringInput | null
  monthlyInterestPayoutsOptIn?: ModelSubscriptionBooleanInput | null
  migrationStatus?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionUserFilterInput | null> | null
  or?: Array<ModelSubscriptionUserFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionIDInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  in?: Array<string | null> | null
  notIn?: Array<string | null> | null
}

export type ModelSubscriptionStringInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  in?: Array<string | null> | null
  notIn?: Array<string | null> | null
}

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null
  eq?: boolean | null
}

export type ModelSubscriptionFloatInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
  in?: Array<number | null> | null
  notIn?: Array<number | null> | null
}

export type ModelSubscriptionDocumentsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  key?: ModelSubscriptionStringInput | null
  bucketName?: ModelSubscriptionStringInput | null
  fileName?: ModelSubscriptionStringInput | null
  name?: ModelSubscriptionStringInput | null
  category?: ModelSubscriptionStringInput | null
  entityType?: ModelSubscriptionStringInput | null
  entityId?: ModelSubscriptionIDInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionDocumentsFilterInput | null> | null
  or?: Array<ModelSubscriptionDocumentsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionLoanFilterInput = {
  id?: ModelSubscriptionIDInput | null
  startDate?: ModelSubscriptionStringInput | null
  endDate?: ModelSubscriptionStringInput | null
  amount?: ModelSubscriptionFloatInput | null
  currency?: ModelSubscriptionStringInput | null
  coverImage?: ModelSubscriptionStringInput | null
  sector?: ModelSubscriptionStringInput | null
  title?: ModelSubscriptionStringInput | null
  country?: ModelSubscriptionStringInput | null
  countryFlag?: ModelSubscriptionStringInput | null
  textOverview?: ModelSubscriptionStringInput | null
  textUseOfFunds?: ModelSubscriptionStringInput | null
  Status?: ModelSubscriptionStringInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  userID?: ModelSubscriptionIDInput | null
  and?: Array<ModelSubscriptionLoanFilterInput | null> | null
  or?: Array<ModelSubscriptionLoanFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionWithdrawalsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  userID?: ModelSubscriptionIDInput | null
  amount?: ModelSubscriptionFloatInput | null
  currency?: ModelSubscriptionStringInput | null
  datePaidOut?: ModelSubscriptionStringInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  withdrawalStatus?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionWithdrawalsFilterInput | null> | null
  or?: Array<ModelSubscriptionWithdrawalsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionInvestmentFilterInput = {
  id?: ModelSubscriptionIDInput | null
  startDate?: ModelSubscriptionStringInput | null
  endDate?: ModelSubscriptionStringInput | null
  actualEndDate?: ModelSubscriptionStringInput | null
  annualInterestRate?: ModelSubscriptionFloatInput | null
  renewFlag?: ModelSubscriptionBooleanInput | null
  renewOption?: ModelSubscriptionStringInput | null
  currency?: ModelSubscriptionStringInput | null
  principleAmount?: ModelSubscriptionFloatInput | null
  InterestCycleDays?: ModelSubscriptionIntInput | null
  tenorDays?: ModelSubscriptionIntInput | null
  expectedTotalInterest?: ModelSubscriptionFloatInput | null
  expectedInterestPerInterestCycle?: ModelSubscriptionFloatInput | null
  expectedNumberOfInterestCycles?: ModelSubscriptionFloatInput | null
  interestPayoutOption?: ModelSubscriptionStringInput | null
  funded?: ModelSubscriptionBooleanInput | null
  fundedAt?: ModelSubscriptionStringInput | null
  Status?: ModelSubscriptionStringInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  userID?: ModelSubscriptionIDInput | null
  isBackdated?: ModelSubscriptionBooleanInput | null
  nextInterestPerInterestCycle?: ModelSubscriptionFloatInput | null
  isRenewed?: ModelSubscriptionBooleanInput | null
  investmentStep?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionInvestmentFilterInput | null> | null
  or?: Array<ModelSubscriptionInvestmentFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionIntInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
  in?: Array<number | null> | null
  notIn?: Array<number | null> | null
}

export type ModelSubscriptionPaymentsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  paymentCategory?: ModelSubscriptionStringInput | null
  paymentType?: ModelSubscriptionStringInput | null
  currency?: ModelSubscriptionStringInput | null
  amount?: ModelSubscriptionFloatInput | null
  expectedDate?: ModelSubscriptionStringInput | null
  actualDate?: ModelSubscriptionStringInput | null
  initiatedBy?: ModelSubscriptionStringInput | null
  notes?: ModelSubscriptionStringInput | null
  Status?: ModelSubscriptionStringInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  userID?: ModelSubscriptionIDInput | null
  investmentID?: ModelSubscriptionIDInput | null
  and?: Array<ModelSubscriptionPaymentsFilterInput | null> | null
  or?: Array<ModelSubscriptionPaymentsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionMonthlyInterestBankPayoutsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  amount?: ModelSubscriptionFloatInput | null
  currency?: ModelSubscriptionStringInput | null
  firstName?: ModelSubscriptionStringInput | null
  lastName?: ModelSubscriptionStringInput | null
  email?: ModelSubscriptionStringInput | null
  phoneNumber?: ModelSubscriptionStringInput | null
  country?: ModelSubscriptionStringInput | null
  walletPayinDate?: ModelSubscriptionStringInput | null
  dueBankPayoutDate?: ModelSubscriptionStringInput | null
  payoutStatus?: ModelSubscriptionStringInput | null
  paymentCategory?: ModelSubscriptionStringInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  userID?: ModelSubscriptionIDInput | null
  investmentID?: ModelSubscriptionIDInput | null
  paymentID?: ModelSubscriptionIDInput | null
  and?: Array<ModelSubscriptionMonthlyInterestBankPayoutsFilterInput | null> | null
  or?: Array<ModelSubscriptionMonthlyInterestBankPayoutsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionDueInterestBankPayoutsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  amount?: ModelSubscriptionFloatInput | null
  currency?: ModelSubscriptionStringInput | null
  firstName?: ModelSubscriptionStringInput | null
  lastName?: ModelSubscriptionStringInput | null
  email?: ModelSubscriptionStringInput | null
  phoneNumber?: ModelSubscriptionStringInput | null
  country?: ModelSubscriptionStringInput | null
  walletPayinDate?: ModelSubscriptionStringInput | null
  dueBankPayoutDate?: ModelSubscriptionStringInput | null
  payoutStatus?: ModelSubscriptionStringInput | null
  paymentCategory?: ModelSubscriptionStringInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  userID?: ModelSubscriptionIDInput | null
  investmentID?: ModelSubscriptionIDInput | null
  paymentID?: ModelSubscriptionIDInput | null
  and?: Array<ModelSubscriptionDueInterestBankPayoutsFilterInput | null> | null
  or?: Array<ModelSubscriptionDueInterestBankPayoutsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionReferencesFilterInput = {
  id?: ModelSubscriptionIDInput | null
  reference?: ModelSubscriptionStringInput | null
  amount?: ModelSubscriptionFloatInput | null
  currency?: ModelSubscriptionStringInput | null
  Status?: ModelSubscriptionStringInput | null
  ProofOfPaymentUrl?: ModelSubscriptionStringInput | null
  notes?: ModelSubscriptionStringInput | null
  investmentID?: ModelSubscriptionIDInput | null
  userID?: ModelSubscriptionIDInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  receivedAt?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionReferencesFilterInput | null> | null
  or?: Array<ModelSubscriptionReferencesFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionFxTransactionsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  fxRate?: ModelSubscriptionFloatInput | null
  fromCurrency?: ModelSubscriptionStringInput | null
  fromAmount?: ModelSubscriptionFloatInput | null
  toCurrency?: ModelSubscriptionStringInput | null
  toAmount?: ModelSubscriptionFloatInput | null
  currencyPair?: ModelSubscriptionStringInput | null
  fee?: ModelSubscriptionFloatInput | null
  buyPrice?: ModelSubscriptionFloatInput | null
  sellPrice?: ModelSubscriptionFloatInput | null
  Status?: ModelSubscriptionStringInput | null
  ProofOfPaymentUrl?: ModelSubscriptionStringInput | null
  userReference?: ModelSubscriptionStringInput | null
  convertFromWallet?: ModelSubscriptionBooleanInput | null
  investmentID?: ModelSubscriptionIDInput | null
  userID?: ModelSubscriptionIDInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionFxTransactionsFilterInput | null> | null
  or?: Array<ModelSubscriptionFxTransactionsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type ModelSubscriptionPlatformMetricsFilterInput = {
  id?: ModelSubscriptionIDInput | null
  createdAt?: ModelSubscriptionStringInput | null
  updatedAt?: ModelSubscriptionStringInput | null
  and?: Array<ModelSubscriptionPlatformMetricsFilterInput | null> | null
  or?: Array<ModelSubscriptionPlatformMetricsFilterInput | null> | null
  owner?: ModelStringInput | null
}

export type GetUserCustomQueryVariables = {
  id: string
}

export type GetUserCustomQuery = {
  getUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    taxId?: string | null
    country?: string | null
    state?: string | null
    city?: string | null
    nationality?: string | null
    referral?: string | null
    hearAboutUs?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      currency?: string | null
      country?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      country?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      items: Array<{
        __typename: 'References'
        id: string
        reference: string
        amount: number
        currency?: string | null
        Status?: ReferencesStatus | null
        notes?: string | null
        userID: string
      } | null>
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
      USD?: {
        __typename: 'WalletDetails'
        id?: string | null
        accountNumber?: string | null
        currency?: string | null
      } | null
      NGN?: {
        __typename: 'WalletDetails'
        id?: string | null
        accountNumber?: string | null
        currency?: string | null
      } | null
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    owner?: string | null
  } | null
}

export type GetReferencesCustomQueryVariables = {
  id: string
}

export type GetReferencesCustomQuery = {
  getReferences?: {
    __typename: 'References'
    id: string
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type GetInvestmentsByUserCustomQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelInvestmentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetInvestmentsByUserCustomQuery = {
  getInvestmentsByUser?: {
    __typename: 'ModelInvestmentConnection'
    items: Array<{
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      investmentStep?: InvestmentSteps | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      user?: {
        __typename: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email?: string | null
        dateOfBirth?: string | null
        phoneNumber?: string | null
        taxId?: string | null
        country?: string | null
        state?: string | null
        city?: string | null
        street?: string | null
        postalCode?: string | null
        legalAgreements?: boolean | null
        createdAt: string
        updatedAt: string
        minimumInvestment?: number | null
        minimumDeposit?: number | null
        clientId?: string | null
        accountId?: string | null
        onboardingStatus?: OnboardingStatus | null
        kycVerificationStatus?: KycVerificationStatus | null
        monthlyInterestPayoutsOptIn?: boolean | null
        owner?: string | null
      } | null
      references?: {
        __typename: 'ModelReferencesConnection'
        nextToken?: string | null
        items: Array<{
          __typename: 'References'
          id: string
          Status?: ReferencesStatus | null
          amount: number
          ProofOfPaymentUrl?: string | null
          createdAt: string
          updatedAt: string
        } | null>
      } | null
      fxTransactions?: {
        __typename: 'ModelFxTransactionsConnection'
        nextToken?: string | null
        items: Array<{
          __typename: 'FxTransactions'
          Status?: FxTransactionsStatus | null
          ProofOfPaymentUrl?: string | null
          createdAt: string
          updatedAt: string
        } | null>
      } | null
      payments?: {
        __typename: 'ModelPaymentsConnection'
        items: Array<{
          __typename: 'Payments'
          id: string
          paymentCategory?: PaymentCategory | null
          paymentType?: PaymentType | null
          currency?: string | null
          amount?: number | null
          expectedDate?: string | null
          actualDate?: string | null
          initiatedBy?: string | null
          notes?: string | null
          Status?: PaymentStatus | null
          createdAt: string
          updatedAt: string
          userID: string
          investmentID: string
          owner?: string | null
        } | null>
        nextToken?: string | null
      } | null
      monthlyInterestBankPayouts?: {
        __typename: 'ModelMonthlyInterestBankPayoutsConnection'
        nextToken?: string | null
      } | null
      dueInterestBankPayouts?: {
        __typename: 'ModelDueInterestBankPayoutsConnection'
        nextToken?: string | null
      } | null
      investmentAgreement?: {
        __typename: 'InvestmentAgreement'
        signed?: boolean | null
        signedAgreementUrl?: string | null
        signedAt: string
        signature?: string | null
      } | null
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvestmentCustomQueryVariables = {
  id: string
}

export type GetInvestmentCustomQuery = {
  getInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      items: Array<{
        __typename: 'Payments'
        id: string
        Status?: PaymentStatus | null
        paymentCategory?: PaymentCategory | null
        paymentType?: PaymentType | null
        amount?: number | null
        currency?: string | null
      } | null>
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      items: Array<{
        __typename: 'References'
        id: string
        investmentID?: string | null
        Status?: ReferencesStatus | null
        ProofOfPaymentUrl?: string | null
      } | null>
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type UpdateReferencesCustomMutationVariables = {
  input: UpdateReferencesInput
  condition?: ModelReferencesConditionInput | null
}

export type UpdateReferencesCustomMutation = {
  updateReferences?: {
    __typename: 'References'
    id: string
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type CreateUserMutationVariables = {
  input: CreateUserInput
  condition?: ModelUserConditionInput | null
}

export type CreateUserMutation = {
  createUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type UpdateUserMutationVariables = {
  input: UpdateUserInput
  condition?: ModelUserConditionInput | null
}

export type UpdateUserMutation = {
  updateUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type DeleteUserMutationVariables = {
  input: DeleteUserInput
  condition?: ModelUserConditionInput | null
}

export type DeleteUserMutation = {
  deleteUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type CreateDocumentsMutationVariables = {
  input: CreateDocumentsInput
  condition?: ModelDocumentsConditionInput | null
}

export type CreateDocumentsMutation = {
  createDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type UpdateDocumentsMutationVariables = {
  input: UpdateDocumentsInput
  condition?: ModelDocumentsConditionInput | null
}

export type UpdateDocumentsMutation = {
  updateDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type DeleteDocumentsMutationVariables = {
  input: DeleteDocumentsInput
  condition?: ModelDocumentsConditionInput | null
}

export type DeleteDocumentsMutation = {
  deleteDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type CreateLoanMutationVariables = {
  input: CreateLoanInput
  condition?: ModelLoanConditionInput | null
}

export type CreateLoanMutation = {
  createLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdateLoanMutationVariables = {
  input: UpdateLoanInput
  condition?: ModelLoanConditionInput | null
}

export type UpdateLoanMutation = {
  updateLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeleteLoanMutationVariables = {
  input: DeleteLoanInput
  condition?: ModelLoanConditionInput | null
}

export type DeleteLoanMutation = {
  deleteLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateWithdrawalsMutationVariables = {
  input: CreateWithdrawalsInput
  condition?: ModelWithdrawalsConditionInput | null
}

export type CreateWithdrawalsMutation = {
  createWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdateWithdrawalsMutationVariables = {
  input: UpdateWithdrawalsInput
  condition?: ModelWithdrawalsConditionInput | null
}

export type UpdateWithdrawalsMutation = {
  updateWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeleteWithdrawalsMutationVariables = {
  input: DeleteWithdrawalsInput
  condition?: ModelWithdrawalsConditionInput | null
}

export type DeleteWithdrawalsMutation = {
  deleteWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateInvestmentMutationVariables = {
  input: CreateInvestmentInput
  condition?: ModelInvestmentConditionInput | null
}

export type CreateInvestmentMutation = {
  createInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type UpdateInvestmentMutationVariables = {
  input: UpdateInvestmentInput
  condition?: ModelInvestmentConditionInput | null
}

export type UpdateInvestmentMutation = {
  updateInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type DeleteInvestmentMutationVariables = {
  input: DeleteInvestmentInput
  condition?: ModelInvestmentConditionInput | null
}

export type DeleteInvestmentMutation = {
  deleteInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type CreatePaymentsMutationVariables = {
  input: CreatePaymentsInput
  condition?: ModelPaymentsConditionInput | null
}

export type CreatePaymentsMutation = {
  createPayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdatePaymentsMutationVariables = {
  input: UpdatePaymentsInput
  condition?: ModelPaymentsConditionInput | null
}

export type UpdatePaymentsMutation = {
  updatePayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeletePaymentsMutationVariables = {
  input: DeletePaymentsInput
  condition?: ModelPaymentsConditionInput | null
}

export type DeletePaymentsMutation = {
  deletePayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateMonthlyInterestBankPayoutsMutationVariables = {
  input: CreateMonthlyInterestBankPayoutsInput
  condition?: ModelMonthlyInterestBankPayoutsConditionInput | null
}

export type CreateMonthlyInterestBankPayoutsMutation = {
  createMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdateMonthlyInterestBankPayoutsMutationVariables = {
  input: UpdateMonthlyInterestBankPayoutsInput
  condition?: ModelMonthlyInterestBankPayoutsConditionInput | null
}

export type UpdateMonthlyInterestBankPayoutsMutation = {
  updateMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeleteMonthlyInterestBankPayoutsMutationVariables = {
  input: DeleteMonthlyInterestBankPayoutsInput
  condition?: ModelMonthlyInterestBankPayoutsConditionInput | null
}

export type DeleteMonthlyInterestBankPayoutsMutation = {
  deleteMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateDueInterestBankPayoutsMutationVariables = {
  input: CreateDueInterestBankPayoutsInput
  condition?: ModelDueInterestBankPayoutsConditionInput | null
}

export type CreateDueInterestBankPayoutsMutation = {
  createDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type UpdateDueInterestBankPayoutsMutationVariables = {
  input: UpdateDueInterestBankPayoutsInput
  condition?: ModelDueInterestBankPayoutsConditionInput | null
}

export type UpdateDueInterestBankPayoutsMutation = {
  updateDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type DeleteDueInterestBankPayoutsMutationVariables = {
  input: DeleteDueInterestBankPayoutsInput
  condition?: ModelDueInterestBankPayoutsConditionInput | null
}

export type DeleteDueInterestBankPayoutsMutation = {
  deleteDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type CreateReferencesMutationVariables = {
  input: CreateReferencesInput
  condition?: ModelReferencesConditionInput | null
}

export type CreateReferencesMutation = {
  createReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type UpdateReferencesMutationVariables = {
  input: UpdateReferencesInput
  condition?: ModelReferencesConditionInput | null
}

export type UpdateReferencesMutation = {
  updateReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type DeleteReferencesMutationVariables = {
  input: DeleteReferencesInput
  condition?: ModelReferencesConditionInput | null
}

export type DeleteReferencesMutation = {
  deleteReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type CreateFxTransactionsMutationVariables = {
  input: CreateFxTransactionsInput
  condition?: ModelFxTransactionsConditionInput | null
}

export type CreateFxTransactionsMutation = {
  createFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type UpdateFxTransactionsMutationVariables = {
  input: UpdateFxTransactionsInput
  condition?: ModelFxTransactionsConditionInput | null
}

export type UpdateFxTransactionsMutation = {
  updateFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type DeleteFxTransactionsMutationVariables = {
  input: DeleteFxTransactionsInput
  condition?: ModelFxTransactionsConditionInput | null
}

export type DeleteFxTransactionsMutation = {
  deleteFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type CreatePlatformMetricsMutationVariables = {
  input: CreatePlatformMetricsInput
  condition?: ModelPlatformMetricsConditionInput | null
}

export type CreatePlatformMetricsMutation = {
  createPlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type UpdatePlatformMetricsMutationVariables = {
  input: UpdatePlatformMetricsInput
  condition?: ModelPlatformMetricsConditionInput | null
}

export type UpdatePlatformMetricsMutation = {
  updatePlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type DeletePlatformMetricsMutationVariables = {
  input: DeletePlatformMetricsInput
  condition?: ModelPlatformMetricsConditionInput | null
}

export type DeletePlatformMetricsMutation = {
  deletePlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type GetUserBalancesQueryVariables = {
  uid?: string | null
  accountId?: string | null
}

export type GetUserBalancesQuery = {
  GetUserBalances?: {
    __typename: 'UserBalances'
    walletBalance?: {
      __typename: 'WalletBalances'
      USD?: number | null
      NGN?: number | null
    } | null
    activeInvestment?: {
      __typename: 'WalletBalances'
      USD?: number | null
      NGN?: number | null
    } | null
    totalBalance?: {
      __typename: 'WalletBalances'
      USD?: number | null
      NGN?: number | null
    } | null
    lifetimeInvestments?: {
      __typename: 'WalletBalances'
      USD?: number | null
      NGN?: number | null
    } | null
    lifetimeInterestEarned?: {
      __typename: 'WalletBalances'
      USD?: number | null
      NGN?: number | null
    } | null
    weightedAnnualInterestRate?: {
      __typename: 'WalletBalances'
      USD?: number | null
      NGN?: number | null
    } | null
    nextInterestPayout?: {
      __typename: 'InterestPayout'
      amount?: number | null
      currency?: string | null
      expectedDate?: string | null
    } | null
    lastWithdrawalAmount?: number | null
  } | null
}

export type SendOTPTokenQueryVariables = {
  userInfo?: UserInfo | null
  nameOfAction?: string | null
}

export type SendOTPTokenQuery = {
  sendOTPToken?: {
    __typename: 'OTPTokenResponse'
    status?: number | null
    message?: string | null
    tokenValid?: boolean | null
  } | null
}

export type VerifyOTPTokenQueryVariables = {
  userInfo?: UserInfo | null
  token?: string | null
}

export type VerifyOTPTokenQuery = {
  verifyOTPToken?: {
    __typename: 'OTPTokenResponse'
    status?: number | null
    message?: string | null
    tokenValid?: boolean | null
  } | null
}

export type InitiateWithdrawalRequestQueryVariables = {
  userInfo?: UserInfo | null
  amount?: number | null
  currency?: string | null
}

export type InitiateWithdrawalRequestQuery = {
  initiateWithdrawalRequest?: {
    __typename: 'WithdrawalResponse'
    message?: string | null
    error?: string | null
    status?: number | null
  } | null
}

export type CustomGenericMailSenderQueryVariables = {
  recipientMails?: Array<string | null> | null
  mailSubject?: string | null
  mailBody?: string | null
  ccMails?: Array<string | null> | null
  bccMails?: Array<string | null> | null
  replyAddresses?: Array<string | null> | null
}

export type CustomGenericMailSenderQuery = {
  customGenericMailSender?: string | null
}

export type GetExchangeRatesQueryVariables = {
  sourceCurrency?: string | null
  destinationCurrency?: string | null
}

export type GetExchangeRatesQuery = {
  GetExchangeRates?: {
    __typename: 'GetExchangeRatesResponse'
    exchangeRate?: number | null
  } | null
}

export type GetSigningUrlQueryVariables = {
  signature?: string | null
  investmentID?: string | null
  type?: AgreementTypes | null
  userIP?: string | null
}

export type GetSigningUrlQuery = {
  GetSigningUrl?: {
    __typename: 'GetSigningUrlResponse'
    signatureUrl?: string | null
  } | null
}

export type GetAllPaymentsHistoryQueryVariables = {
  options?: string | null
}

export type GetAllPaymentsHistoryQuery = {
  GetAllPaymentsHistory?: string | null
}

export type GetUserAccountStatementQueryVariables = {
  options?: string | null
}

export type GetUserAccountStatementQuery = {
  GetUserAccountStatement?: string | null
}

export type GetUserQueryVariables = {
  id: string
}

export type GetUserQuery = {
  getUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListUsersQuery = {
  listUsers?: {
    __typename: 'ModelUserConnection'
    items: Array<{
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetDocumentsQueryVariables = {
  id: string
}

export type GetDocumentsQuery = {
  getDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListDocumentsQuery = {
  listDocuments?: {
    __typename: 'ModelDocumentsConnection'
    items: Array<{
      __typename: 'Documents'
      id: string
      key?: string | null
      bucketName?: string | null
      fileName?: string | null
      name?: string | null
      category?: DocumentCategory | null
      entityType?: EntityTypes | null
      entityId: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type DocumentsByUserQueryVariables = {
  entityId: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelDocumentsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type DocumentsByUserQuery = {
  documentsByUser?: {
    __typename: 'ModelDocumentsConnection'
    items: Array<{
      __typename: 'Documents'
      id: string
      key?: string | null
      bucketName?: string | null
      fileName?: string | null
      name?: string | null
      category?: DocumentCategory | null
      entityType?: EntityTypes | null
      entityId: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetLoanQueryVariables = {
  id: string
}

export type GetLoanQuery = {
  getLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListLoansQueryVariables = {
  filter?: ModelLoanFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListLoansQuery = {
  listLoans?: {
    __typename: 'ModelLoanConnection'
    items: Array<{
      __typename: 'Loan'
      id: string
      startDate?: string | null
      endDate?: string | null
      amount?: number | null
      currency?: string | null
      coverImage?: string | null
      sector?: string | null
      title?: string | null
      country?: string | null
      countryFlag?: string | null
      textOverview?: string | null
      textUseOfFunds?: string | null
      Status?: LoanStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetLoansByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelLoanFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetLoansByUserQuery = {
  getLoansByUser?: {
    __typename: 'ModelLoanConnection'
    items: Array<{
      __typename: 'Loan'
      id: string
      startDate?: string | null
      endDate?: string | null
      amount?: number | null
      currency?: string | null
      coverImage?: string | null
      sector?: string | null
      title?: string | null
      country?: string | null
      countryFlag?: string | null
      textOverview?: string | null
      textUseOfFunds?: string | null
      Status?: LoanStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetWithdrawalsQueryVariables = {
  id: string
}

export type GetWithdrawalsQuery = {
  getWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListWithdrawalsQueryVariables = {
  filter?: ModelWithdrawalsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListWithdrawalsQuery = {
  listWithdrawals?: {
    __typename: 'ModelWithdrawalsConnection'
    items: Array<{
      __typename: 'Withdrawals'
      id: string
      userID: string
      amount?: number | null
      currency?: string | null
      datePaidOut?: string | null
      createdAt: string
      updatedAt: string
      withdrawalStatus?: WithdrawalStatus | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetWithdrawalsByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelWithdrawalsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetWithdrawalsByUserQuery = {
  getWithdrawalsByUser?: {
    __typename: 'ModelWithdrawalsConnection'
    items: Array<{
      __typename: 'Withdrawals'
      id: string
      userID: string
      amount?: number | null
      currency?: string | null
      datePaidOut?: string | null
      createdAt: string
      updatedAt: string
      withdrawalStatus?: WithdrawalStatus | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvestmentQueryVariables = {
  id: string
}

export type GetInvestmentQuery = {
  getInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type ListInvestmentsQueryVariables = {
  filter?: ModelInvestmentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListInvestmentsQuery = {
  listInvestments?: {
    __typename: 'ModelInvestmentConnection'
    items: Array<{
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvestmentsByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelInvestmentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetInvestmentsByUserQuery = {
  getInvestmentsByUser?: {
    __typename: 'ModelInvestmentConnection'
    items: Array<{
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPaymentsQueryVariables = {
  id: string
}

export type GetPaymentsQuery = {
  getPayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPaymentsQuery = {
  listPayments?: {
    __typename: 'ModelPaymentsConnection'
    items: Array<{
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type PaymentsByExpectedDateQueryVariables = {
  expectedDate: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelPaymentsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type PaymentsByExpectedDateQuery = {
  paymentsByExpectedDate?: {
    __typename: 'ModelPaymentsConnection'
    items: Array<{
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPaymentsByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPaymentsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetPaymentsByUserQuery = {
  getPaymentsByUser?: {
    __typename: 'ModelPaymentsConnection'
    items: Array<{
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPaymentsByInvestmentQueryVariables = {
  investmentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPaymentsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetPaymentsByInvestmentQuery = {
  getPaymentsByInvestment?: {
    __typename: 'ModelPaymentsConnection'
    items: Array<{
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMonthlyInterestBankPayoutsQueryVariables = {
  id: string
}

export type GetMonthlyInterestBankPayoutsQuery = {
  getMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListMonthlyInterestBankPayoutsQueryVariables = {
  filter?: ModelMonthlyInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListMonthlyInterestBankPayoutsQuery = {
  listMonthlyInterestBankPayouts?: {
    __typename: 'ModelMonthlyInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'MonthlyInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMonthlyInterestPayoutsByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMonthlyInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMonthlyInterestPayoutsByUserQuery = {
  getMonthlyInterestPayoutsByUser?: {
    __typename: 'ModelMonthlyInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'MonthlyInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMonthlyPayoutsByInvestmentQueryVariables = {
  investmentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMonthlyInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMonthlyPayoutsByInvestmentQuery = {
  getMonthlyPayoutsByInvestment?: {
    __typename: 'ModelMonthlyInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'MonthlyInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMonthlyPayoutsByPaymentQueryVariables = {
  paymentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMonthlyInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMonthlyPayoutsByPaymentQuery = {
  getMonthlyPayoutsByPayment?: {
    __typename: 'ModelMonthlyInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'MonthlyInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetDueInterestBankPayoutsQueryVariables = {
  id: string
}

export type GetDueInterestBankPayoutsQuery = {
  getDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type ListDueInterestBankPayoutsQueryVariables = {
  filter?: ModelDueInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListDueInterestBankPayoutsQuery = {
  listDueInterestBankPayouts?: {
    __typename: 'ModelDueInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'DueInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetDueInterestBankPayoutsByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelDueInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetDueInterestBankPayoutsByUserQuery = {
  getDueInterestBankPayoutsByUser?: {
    __typename: 'ModelDueInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'DueInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetDueInterestBankPayoutsByInvestmentQueryVariables = {
  investmentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelDueInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetDueInterestBankPayoutsByInvestmentQuery = {
  getDueInterestBankPayoutsByInvestment?: {
    __typename: 'ModelDueInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'DueInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetDueInterestBankPayoutsByPaymentQueryVariables = {
  paymentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelDueInterestBankPayoutsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetDueInterestBankPayoutsByPaymentQuery = {
  getDueInterestBankPayoutsByPayment?: {
    __typename: 'ModelDueInterestBankPayoutsConnection'
    items: Array<{
      __typename: 'DueInterestBankPayouts'
      id: string
      amount?: number | null
      currency?: string | null
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      phoneNumber?: string | null
      country?: string | null
      walletPayinDate: string
      dueBankPayoutDate: string
      payoutStatus?: PaymentStatus | null
      paymentCategory?: PaymentCategory | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      paymentID: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetReferencesQueryVariables = {
  id: string
}

export type GetReferencesQuery = {
  getReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type ListReferencesQueryVariables = {
  filter?: ModelReferencesFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListReferencesQuery = {
  listReferences?: {
    __typename: 'ModelReferencesConnection'
    items: Array<{
      __typename: 'References'
      id: string
      reference: string
      amount: number
      currency?: string | null
      Status?: ReferencesStatus | null
      ProofOfPaymentUrl?: string | null
      notes?: string | null
      investmentID?: string | null
      userID: string
      createdAt: string
      updatedAt: string
      receivedAt?: string | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvestmentByReferenceQueryVariables = {
  investmentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelReferencesFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetInvestmentByReferenceQuery = {
  getInvestmentByReference?: {
    __typename: 'ModelReferencesConnection'
    items: Array<{
      __typename: 'References'
      id: string
      reference: string
      amount: number
      currency?: string | null
      Status?: ReferencesStatus | null
      ProofOfPaymentUrl?: string | null
      notes?: string | null
      investmentID?: string | null
      userID: string
      createdAt: string
      updatedAt: string
      receivedAt?: string | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetReferencesByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelReferencesFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetReferencesByUserQuery = {
  getReferencesByUser?: {
    __typename: 'ModelReferencesConnection'
    items: Array<{
      __typename: 'References'
      id: string
      reference: string
      amount: number
      currency?: string | null
      Status?: ReferencesStatus | null
      ProofOfPaymentUrl?: string | null
      notes?: string | null
      investmentID?: string | null
      userID: string
      createdAt: string
      updatedAt: string
      receivedAt?: string | null
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetFxTransactionsQueryVariables = {
  id: string
}

export type GetFxTransactionsQuery = {
  getFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type ListFxTransactionsQueryVariables = {
  filter?: ModelFxTransactionsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListFxTransactionsQuery = {
  listFxTransactions?: {
    __typename: 'ModelFxTransactionsConnection'
    items: Array<{
      __typename: 'FxTransactions'
      id: string
      fxRate?: number | null
      fromCurrency?: string | null
      fromAmount?: number | null
      toCurrency?: string | null
      toAmount?: number | null
      currencyPair?: string | null
      fee?: number | null
      buyPrice?: number | null
      sellPrice?: number | null
      Status?: FxTransactionsStatus | null
      ProofOfPaymentUrl?: string | null
      userReference: string
      convertFromWallet?: boolean | null
      investmentID?: string | null
      userID?: string | null
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvestmentByFxTransactionsQueryVariables = {
  investmentID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelFxTransactionsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetInvestmentByFxTransactionsQuery = {
  getInvestmentByFxTransactions?: {
    __typename: 'ModelFxTransactionsConnection'
    items: Array<{
      __typename: 'FxTransactions'
      id: string
      fxRate?: number | null
      fromCurrency?: string | null
      fromAmount?: number | null
      toCurrency?: string | null
      toAmount?: number | null
      currencyPair?: string | null
      fee?: number | null
      buyPrice?: number | null
      sellPrice?: number | null
      Status?: FxTransactionsStatus | null
      ProofOfPaymentUrl?: string | null
      userReference: string
      convertFromWallet?: boolean | null
      investmentID?: string | null
      userID?: string | null
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetFxTransactionsByUserQueryVariables = {
  userID: string
  id?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelFxTransactionsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetFxTransactionsByUserQuery = {
  getFxTransactionsByUser?: {
    __typename: 'ModelFxTransactionsConnection'
    items: Array<{
      __typename: 'FxTransactions'
      id: string
      fxRate?: number | null
      fromCurrency?: string | null
      fromAmount?: number | null
      toCurrency?: string | null
      toAmount?: number | null
      currencyPair?: string | null
      fee?: number | null
      buyPrice?: number | null
      sellPrice?: number | null
      Status?: FxTransactionsStatus | null
      ProofOfPaymentUrl?: string | null
      userReference: string
      convertFromWallet?: boolean | null
      investmentID?: string | null
      userID?: string | null
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPlatformMetricsQueryVariables = {
  id: string
}

export type GetPlatformMetricsQuery = {
  getPlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type ListPlatformMetricsQueryVariables = {
  filter?: ModelPlatformMetricsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPlatformMetricsQuery = {
  listPlatformMetrics?: {
    __typename: 'ModelPlatformMetricsConnection'
    items: Array<{
      __typename: 'PlatformMetrics'
      id: string
      createdAt: string
      updatedAt: string
      owner?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null
  owner?: string | null
}

export type OnCreateUserSubscription = {
  onCreateUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null
  owner?: string | null
}

export type OnUpdateUserSubscription = {
  onUpdateUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null
  owner?: string | null
}

export type OnDeleteUserSubscription = {
  onDeleteUser?: {
    __typename: 'User'
    id: string
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    dateOfBirth?: string | null
    phoneNumber?: string | null
    nationality?: string | null
    hearAboutUs?: string | null
    referral?: string | null
    taxId?: string | null
    taxIdType?: TaxTypes | null
    country?: string | null
    state?: string | null
    city?: string | null
    street?: string | null
    postalCode?: string | null
    legalAgreements?: boolean | null
    usAccreditedInvestor?: {
      __typename: 'USAccreditedInvestor'
      annualIncome?: number | null
      netWorth?: string | null
      includesSpouse?: boolean | null
      accreditedInvestorCondition?: string | null
      accreditedInvestorFlag?: boolean | null
      accreditedInvestorExemption?: string | null
    } | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    identification?: {
      __typename: 'Identification'
      idType?: string | null
      idNumber?: string | null
      taxIdType?: TaxTypes | null
      taxId?: string | null
    } | null
    kycDetails?: {
      __typename: 'KycDetails'
      type?: string | null
      identificationNumber?: string | null
      issuingCountry?: string | null
      issueDate?: string | null
      expirationDate?: string | null
      reason?: string | null
      verificationId?: string | null
      documentPhotos?: Array<string | null> | null
      verifiedDelivered?: boolean | null
      rejectedDelivered?: boolean | null
    } | null
    Documents?: {
      __typename: 'ModelDocumentsConnection'
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    minimumInvestment?: number | null
    minimumDeposit?: number | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    withdrawals?: {
      __typename: 'ModelWithdrawalsConnection'
      nextToken?: string | null
    } | null
    loans?: {
      __typename: 'ModelLoanConnection'
      nextToken?: string | null
    } | null
    investments?: {
      __typename: 'ModelInvestmentConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    clientId?: string | null
    accountId?: string | null
    wallets?: {
      __typename: 'Wallets'
    } | null
    otpCode?: {
      __typename: 'OTPCode'
      code?: string | null
      timestamp?: number | null
    } | null
    onboardingStatus?: OnboardingStatus | null
    kycVerificationStatus?: KycVerificationStatus | null
    monthlyInterestPayoutsOptIn?: boolean | null
    migrationStatus?: MigrationStatus | null
    owner?: string | null
  } | null
}

export type OnCreateDocumentsSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentsFilterInput | null
  owner?: string | null
}

export type OnCreateDocumentsSubscription = {
  onCreateDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnUpdateDocumentsSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentsFilterInput | null
  owner?: string | null
}

export type OnUpdateDocumentsSubscription = {
  onUpdateDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnDeleteDocumentsSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentsFilterInput | null
  owner?: string | null
}

export type OnDeleteDocumentsSubscription = {
  onDeleteDocuments?: {
    __typename: 'Documents'
    id: string
    key?: string | null
    bucketName?: string | null
    fileName?: string | null
    name?: string | null
    category?: DocumentCategory | null
    entityType?: EntityTypes | null
    entityId: string
    User?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnCreateLoanSubscriptionVariables = {
  filter?: ModelSubscriptionLoanFilterInput | null
  owner?: string | null
}

export type OnCreateLoanSubscription = {
  onCreateLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateLoanSubscriptionVariables = {
  filter?: ModelSubscriptionLoanFilterInput | null
  owner?: string | null
}

export type OnUpdateLoanSubscription = {
  onUpdateLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteLoanSubscriptionVariables = {
  filter?: ModelSubscriptionLoanFilterInput | null
  owner?: string | null
}

export type OnDeleteLoanSubscription = {
  onDeleteLoan?: {
    __typename: 'Loan'
    id: string
    startDate?: string | null
    endDate?: string | null
    amount?: number | null
    currency?: string | null
    coverImage?: string | null
    sector?: string | null
    title?: string | null
    country?: string | null
    countryFlag?: string | null
    textOverview?: string | null
    textUseOfFunds?: string | null
    Status?: LoanStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateWithdrawalsSubscriptionVariables = {
  filter?: ModelSubscriptionWithdrawalsFilterInput | null
  owner?: string | null
}

export type OnCreateWithdrawalsSubscription = {
  onCreateWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateWithdrawalsSubscriptionVariables = {
  filter?: ModelSubscriptionWithdrawalsFilterInput | null
  owner?: string | null
}

export type OnUpdateWithdrawalsSubscription = {
  onUpdateWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteWithdrawalsSubscriptionVariables = {
  filter?: ModelSubscriptionWithdrawalsFilterInput | null
  owner?: string | null
}

export type OnDeleteWithdrawalsSubscription = {
  onDeleteWithdrawals?: {
    __typename: 'Withdrawals'
    id: string
    userID: string
    amount?: number | null
    currency?: string | null
    datePaidOut?: string | null
    createdAt: string
    updatedAt: string
    withdrawalStatus?: WithdrawalStatus | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateInvestmentSubscriptionVariables = {
  filter?: ModelSubscriptionInvestmentFilterInput | null
  owner?: string | null
}

export type OnCreateInvestmentSubscription = {
  onCreateInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type OnUpdateInvestmentSubscriptionVariables = {
  filter?: ModelSubscriptionInvestmentFilterInput | null
  owner?: string | null
}

export type OnUpdateInvestmentSubscription = {
  onUpdateInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type OnDeleteInvestmentSubscriptionVariables = {
  filter?: ModelSubscriptionInvestmentFilterInput | null
  owner?: string | null
}

export type OnDeleteInvestmentSubscription = {
  onDeleteInvestment?: {
    __typename: 'Investment'
    id: string
    startDate?: string | null
    endDate?: string | null
    actualEndDate?: string | null
    annualInterestRate?: number | null
    renewFlag?: boolean | null
    renewOption?: RenewalOptions | null
    currency?: string | null
    principleAmount?: number | null
    InterestCycleDays?: number | null
    tenorDays?: number | null
    expectedTotalInterest?: number | null
    expectedInterestPerInterestCycle?: number | null
    expectedNumberOfInterestCycles?: number | null
    interestPayoutOption?: InterestPayoutOptions | null
    funded?: boolean | null
    fundedAt?: string | null
    Status?: InvestmentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    payments?: {
      __typename: 'ModelPaymentsConnection'
      nextToken?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    references?: {
      __typename: 'ModelReferencesConnection'
      nextToken?: string | null
    } | null
    fxTransactions?: {
      __typename: 'ModelFxTransactionsConnection'
      nextToken?: string | null
    } | null
    investmentAgreement?: {
      __typename: 'InvestmentAgreement'
      signed?: boolean | null
      signature?: string | null
      signedAgreementUrl?: string | null
      signedAt: string
    } | null
    isBackdated?: boolean | null
    nextInterestPerInterestCycle?: number | null
    isRenewed?: boolean | null
    investmentStep?: InvestmentSteps | null
    owner?: string | null
  } | null
}

export type OnCreatePaymentsSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentsFilterInput | null
  owner?: string | null
}

export type OnCreatePaymentsSubscription = {
  onCreatePayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdatePaymentsSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentsFilterInput | null
  owner?: string | null
}

export type OnUpdatePaymentsSubscription = {
  onUpdatePayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeletePaymentsSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentsFilterInput | null
  owner?: string | null
}

export type OnDeletePaymentsSubscription = {
  onDeletePayments?: {
    __typename: 'Payments'
    id: string
    paymentCategory?: PaymentCategory | null
    paymentType?: PaymentType | null
    currency?: string | null
    amount?: number | null
    expectedDate?: string | null
    actualDate?: string | null
    initiatedBy?: string | null
    notes?: string | null
    Status?: PaymentStatus | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    monthlyInterestBankPayouts?: {
      __typename: 'ModelMonthlyInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    dueInterestBankPayouts?: {
      __typename: 'ModelDueInterestBankPayoutsConnection'
      nextToken?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateMonthlyInterestBankPayoutsSubscriptionVariables = {
  filter?: ModelSubscriptionMonthlyInterestBankPayoutsFilterInput | null
  owner?: string | null
}

export type OnCreateMonthlyInterestBankPayoutsSubscription = {
  onCreateMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateMonthlyInterestBankPayoutsSubscriptionVariables = {
  filter?: ModelSubscriptionMonthlyInterestBankPayoutsFilterInput | null
  owner?: string | null
}

export type OnUpdateMonthlyInterestBankPayoutsSubscription = {
  onUpdateMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteMonthlyInterestBankPayoutsSubscriptionVariables = {
  filter?: ModelSubscriptionMonthlyInterestBankPayoutsFilterInput | null
  owner?: string | null
}

export type OnDeleteMonthlyInterestBankPayoutsSubscription = {
  onDeleteMonthlyInterestBankPayouts?: {
    __typename: 'MonthlyInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateDueInterestBankPayoutsSubscriptionVariables = {
  filter?: ModelSubscriptionDueInterestBankPayoutsFilterInput | null
  owner?: string | null
}

export type OnCreateDueInterestBankPayoutsSubscription = {
  onCreateDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnUpdateDueInterestBankPayoutsSubscriptionVariables = {
  filter?: ModelSubscriptionDueInterestBankPayoutsFilterInput | null
  owner?: string | null
}

export type OnUpdateDueInterestBankPayoutsSubscription = {
  onUpdateDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnDeleteDueInterestBankPayoutsSubscriptionVariables = {
  filter?: ModelSubscriptionDueInterestBankPayoutsFilterInput | null
  owner?: string | null
}

export type OnDeleteDueInterestBankPayoutsSubscription = {
  onDeleteDueInterestBankPayouts?: {
    __typename: 'DueInterestBankPayouts'
    id: string
    amount?: number | null
    currency?: string | null
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phoneNumber?: string | null
    country?: string | null
    bankAccountPayoutUSD?: {
      __typename: 'BankAccountPayoutUSD'
      accountName?: string | null
      accountType?: AccountType | null
      accountNumber?: string | null
      routingType?: RoutingTypes | null
      routingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      swiftCode?: string | null
      bankPostalCode?: string | null
      bankCountry?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      wireRoutingNumber?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientCountry?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    bankAccountPayoutNGN?: {
      __typename: 'BankAccountPayoutNGN'
      accountName?: string | null
      accountType?: string | null
      accountNumber?: string | null
      country?: string | null
      achRoutingNumber?: string | null
      bankName?: string | null
      iban?: string | null
      bankPostalCode?: string | null
      recipientCountry?: string | null
      currency?: string | null
      bankAddress?: string | null
      recipientAddress?: string | null
      memo?: string | null
    } | null
    walletPayinDate: string
    dueBankPayoutDate: string
    payoutStatus?: PaymentStatus | null
    paymentCategory?: PaymentCategory | null
    createdAt: string
    updatedAt: string
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    investmentID: string
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    paymentID: string
    payment?: {
      __typename: 'Payments'
      id: string
      paymentCategory?: PaymentCategory | null
      paymentType?: PaymentType | null
      currency?: string | null
      amount?: number | null
      expectedDate?: string | null
      actualDate?: string | null
      initiatedBy?: string | null
      notes?: string | null
      Status?: PaymentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      investmentID: string
      owner?: string | null
    } | null
    owner?: string | null
  } | null
}

export type OnCreateReferencesSubscriptionVariables = {
  filter?: ModelSubscriptionReferencesFilterInput | null
  owner?: string | null
}

export type OnCreateReferencesSubscription = {
  onCreateReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type OnUpdateReferencesSubscriptionVariables = {
  filter?: ModelSubscriptionReferencesFilterInput | null
  owner?: string | null
}

export type OnUpdateReferencesSubscription = {
  onUpdateReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type OnDeleteReferencesSubscriptionVariables = {
  filter?: ModelSubscriptionReferencesFilterInput | null
  owner?: string | null
}

export type OnDeleteReferencesSubscription = {
  onDeleteReferences?: {
    __typename: 'References'
    id: string
    reference: string
    amount: number
    currency?: string | null
    Status?: ReferencesStatus | null
    ProofOfPaymentUrl?: string | null
    notes?: string | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID: string
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    receivedAt?: string | null
    owner?: string | null
  } | null
}

export type OnCreateFxTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionFxTransactionsFilterInput | null
  owner?: string | null
}

export type OnCreateFxTransactionsSubscription = {
  onCreateFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnUpdateFxTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionFxTransactionsFilterInput | null
  owner?: string | null
}

export type OnUpdateFxTransactionsSubscription = {
  onUpdateFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnDeleteFxTransactionsSubscriptionVariables = {
  filter?: ModelSubscriptionFxTransactionsFilterInput | null
  owner?: string | null
}

export type OnDeleteFxTransactionsSubscription = {
  onDeleteFxTransactions?: {
    __typename: 'FxTransactions'
    id: string
    fxRate?: number | null
    fromCurrency?: string | null
    fromAmount?: number | null
    toCurrency?: string | null
    toAmount?: number | null
    currencyPair?: string | null
    fee?: number | null
    buyPrice?: number | null
    sellPrice?: number | null
    Status?: FxTransactionsStatus | null
    ProofOfPaymentUrl?: string | null
    userReference: string
    convertFromWallet?: boolean | null
    investmentID?: string | null
    investment?: {
      __typename: 'Investment'
      id: string
      startDate?: string | null
      endDate?: string | null
      actualEndDate?: string | null
      annualInterestRate?: number | null
      renewFlag?: boolean | null
      renewOption?: RenewalOptions | null
      currency?: string | null
      principleAmount?: number | null
      InterestCycleDays?: number | null
      tenorDays?: number | null
      expectedTotalInterest?: number | null
      expectedInterestPerInterestCycle?: number | null
      expectedNumberOfInterestCycles?: number | null
      interestPayoutOption?: InterestPayoutOptions | null
      funded?: boolean | null
      fundedAt?: string | null
      Status?: InvestmentStatus | null
      createdAt: string
      updatedAt: string
      userID: string
      isBackdated?: boolean | null
      nextInterestPerInterestCycle?: number | null
      isRenewed?: boolean | null
      investmentStep?: InvestmentSteps | null
      owner?: string | null
    } | null
    userID?: string | null
    user?: {
      __typename: 'User'
      id: string
      firstName?: string | null
      lastName?: string | null
      email?: string | null
      dateOfBirth?: string | null
      phoneNumber?: string | null
      nationality?: string | null
      hearAboutUs?: string | null
      referral?: string | null
      taxId?: string | null
      taxIdType?: TaxTypes | null
      country?: string | null
      state?: string | null
      city?: string | null
      street?: string | null
      postalCode?: string | null
      legalAgreements?: boolean | null
      createdAt: string
      updatedAt: string
      minimumInvestment?: number | null
      minimumDeposit?: number | null
      clientId?: string | null
      accountId?: string | null
      onboardingStatus?: OnboardingStatus | null
      kycVerificationStatus?: KycVerificationStatus | null
      monthlyInterestPayoutsOptIn?: boolean | null
      migrationStatus?: MigrationStatus | null
      owner?: string | null
    } | null
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnCreatePlatformMetricsSubscriptionVariables = {
  filter?: ModelSubscriptionPlatformMetricsFilterInput | null
  owner?: string | null
}

export type OnCreatePlatformMetricsSubscription = {
  onCreatePlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnUpdatePlatformMetricsSubscriptionVariables = {
  filter?: ModelSubscriptionPlatformMetricsFilterInput | null
  owner?: string | null
}

export type OnUpdatePlatformMetricsSubscription = {
  onUpdatePlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}

export type OnDeletePlatformMetricsSubscriptionVariables = {
  filter?: ModelSubscriptionPlatformMetricsFilterInput | null
  owner?: string | null
}

export type OnDeletePlatformMetricsSubscription = {
  onDeletePlatformMetrics?: {
    __typename: 'PlatformMetrics'
    id: string
    data: {
      __typename: 'PlatformMetricsData'
      createdAt: string
    }
    createdAt: string
    updatedAt: string
    owner?: string | null
  } | null
}
